import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import textsReducer from './textsSlice'; // Импорт слайса текстов
import authReducer from './authSlice'; // Импорт слайса аутентификации
import themeReducer from './themeSlice'; // Импорт слайса темы
import ttsReducer from './ttsSlice'; // Импорт слайса tts


// Конфигурация для сохранения состояния в AsyncStorage
const persistConfig = {
  key: 'root', // Ключ для хранения
  storage: AsyncStorage, // Используем AsyncStorage
  whitelist: ['auth', 'theme'], // Сохраняем состояние аутентификации и темы
};

// Комбинируем редьюсеры
const rootReducer = combineReducers({
  texts: textsReducer,
  auth: authReducer,
  theme: themeReducer, // Добавляем редьюсер темы
  tts: ttsReducer,
});

// Создаем persistedReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Настройка хранилища
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Создаем persistor для работы с persisted state
export const persistor = persistStore(store);

export default store;
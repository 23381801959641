import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser, loginWithGoogle, fetchUserProfile } from '../redux/authSlice';
import { GoogleLogin } from '@react-oauth/google';
import {
  Container,
  TextField,
  Button,
  Alert,
  Typography,
  Divider,
  Box,
  Stack,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import './TextDivider.css';
import Logo from './Logo'; // Импортируем компонент Logo

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      await dispatch(fetchUserProfile()).unwrap(); // Загружаем профиль пользователя после успешного логина
      navigate('/'); // Перенаправляем на главную страницу после успешного логина
    } catch (err) {
      setError('Login failed. Please try again.');
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    const token = response.credential;
    try {
      await dispatch(loginWithGoogle(token)).unwrap();
      await dispatch(fetchUserProfile()).unwrap(); // Загружаем профиль пользователя после успешного логина
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    setError('Google login failed');
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 0 }}>
      <Box display="flex" justifyContent="center" mb={0}>
        <Logo />
      </Box>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter e-mail"
          margin="normal"
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          margin="normal"
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, mb: 1 }}
        >
          Login
        </Button>
      </form>

      <Divider sx={{ my: 2 }}>
        <Typography variant="body2" color="textSecondary">
          or
        </Typography>
      </Divider>

      <Box display="flex" justifyContent="center">
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            >
              Login with Google
            </Button>
          )}
        />
      </Box>

      <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
        <Box textAlign="center">
          <Typography variant="body2" color="textSecondary">
            I'm new here.
          </Typography>
          <Button
            component={Link}
            to="/register"
            variant="outlined"
            fullWidth
            sx={{ mt: 1 }}
          >
            Register
          </Button>
        </Box>
        <Box textAlign="center">
          <Typography variant="body2" color="textSecondary">
            Forgot password?
          </Typography>
          <Button
            component={Link}
            to="/password-reset-request"
            variant="outlined"
            fullWidth
            sx={{ mt: 1 }}
          >
            Reset password
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};

export default Login;
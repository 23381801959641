export const transformLang = (lg) => {
    let lg_tr = "";
    switch(lg) {
      case "de":
        lg_tr = 'de-DE'
        break;
      case "ru":
        lg_tr = 'ru-RU'
        break;
      case "en":
        lg_tr = 'en-US'
        break;
      case "es":
        lg_tr = 'es-ES'
        break;
      case "fr":
        lg_tr = 'fr-FR'
        break;
      case "ar":
        lg_tr = 'ar-SA'
        break;
      default:
        lg_tr = lg
    }
    console.log(lg)
    console.log(lg_tr)

    return lg_tr
  };


  export const isTelegramWebApp = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      const urlParams = new URLSearchParams(window.location.search);
      // return urlParams.has('tgWebAppPlatform');
      return true;
    }
    return false;
  };

// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { transformLang } from './Utils';
// import { convert } from 'html-to-text';
// import { Button, Card, Typography, IconButton, Box, Modal, TextField, Switch, FormControlLabel } from '@mui/material';
// import { ArrowBack, Settings } from '@mui/icons-material';
// import { toggleTheme } from '../redux/themeSlice';
// import './Flashcard.css';

// const SuggestiveTrainingPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.auth.user);
//   const theme = useSelector((state) => state.theme.theme);
//   const { sentences, words, isWordsMode } = location.state || {};
//   const [currentSuggestiveIndex, setCurrentSuggestiveIndex] = useState(0);
//   const [randomPosition, setRandomPosition] = useState({ top: '50%', left: '50%' });
//   const [intervalTime, setIntervalTime] = useState(10000);
//   const [positionMode, setPositionMode] = useState('center');
//   const [fontSize, setFontSize] = useState(1.5);
//   const [playAudio, setPlayAudio] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const animationFrameRef = useRef(null);
//   const textContainerRef = useRef(null);
//   const sortedSentences = sentences?.sort((a, b) => a.order - b.order) || [];
//   const trainingData = isWordsMode ? words : sortedSentences || [];
//   const totalItems = trainingData.length; // Общее количество элементов
//   const progressText = `${currentSuggestiveIndex + 1}/${totalItems}`; // Текст прогресса

//   useEffect(() => {
//     if (trainingData.length === 0) return;

//     let startTime = Date.now();

//     const animate = () => {
//       const currentTime = Date.now();
//       if (currentTime - startTime >= intervalTime) {
//         startTime = currentTime;
//         setCurrentSuggestiveIndex((prevIndex) => {
//           if (prevIndex >= trainingData.length - 1) {
//             return 0; // Сбрасываем индекс, если достигли конца списка
//           }
//           return prevIndex + 1;
//         });

//         if (positionMode === 'random') {
//           const textContainer = textContainerRef.current;
//           if (textContainer) {
//             const parentRect = textContainer.parentElement.getBoundingClientRect();
//             const textRect = textContainer.getBoundingClientRect();

//             const maxTop = parentRect.height - textRect.height;
//             const maxLeft = parentRect.width - textRect.width;

//             const randomTop = Math.max(0, Math.min(maxTop, Math.random() * maxTop));
//             const randomLeft = Math.max(0, Math.min(maxLeft, Math.random() * maxLeft));

//             setRandomPosition({
//               top: `${(randomTop / parentRect.height) * 100}%`,
//               left: `${(randomLeft / parentRect.width) * 100}%`,
//             });
//           }
//         } else {
//           setRandomPosition({ top: '50%', left: '50%' });
//         }
//       }

//       animationFrameRef.current = requestAnimationFrame(animate);
//     };

//     animationFrameRef.current = requestAnimationFrame(animate);

//     return () => cancelAnimationFrame(animationFrameRef.current);
//   }, [trainingData, intervalTime, positionMode]);

//   useEffect(() => {
//     if (trainingData.length === 0) return;

//     if (playAudio && trainingData[currentSuggestiveIndex]) {
//       const text = isWordsMode ? trainingData[currentSuggestiveIndex].text : trainingData[currentSuggestiveIndex].text;
//       handlePlay(text);
//     }
//   }, [currentSuggestiveIndex, playAudio, trainingData, isWordsMode]);

//   const handlePlay = (text) => {
//     if (!user || !user.text_language) {
//       console.error("User data is not available or text_language is missing.");
//       return;
//     }

//     const cleanText = stripHtmlTags(text);
//     console.log(cleanText);

//     if (window.ReactNativeWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({
//         type: 'speak',
//         lang: transformLang(user.text_language),
//         text: cleanText,
//         comp: 'SentenceList',
//         voice: user.text_voice
//       }));
//     } else {
//       const newUtterance = new SpeechSynthesisUtterance(cleanText);
//       newUtterance.lang = transformLang(user.text_language);
//       speechSynthesis.speak(newUtterance);
//     }
//   };

//   const stripHtmlTags = (html) => {
//     return convert(html, {
//       formatters: {
//         'fooBlockFormatter': function (elem, walk, builder, formatOptions) {
//           builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
//           walk(elem.children, builder);
//           builder.addInline('!');
//           builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
//         }
//       },
//       selectors: [
//         {
//           selector: 'foo',
//           format: 'fooBlockFormatter',
//           options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
//         }
//       ]
//     });
//   };

//   if (trainingData.length === 0) {
//     return (
//       <Box sx={{ padding: 2 }}>
//         <IconButton onClick={() => navigate(-1)}>
//           <ArrowBack />
//         </IconButton>
//         <Typography variant="h4" align="center" gutterBottom>
//           Training
//         </Typography>
//         <Typography variant="body1" align="center">
//           No data available for training.
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <IconButton onClick={() => navigate(-1)}>
//           <ArrowBack />
//         </IconButton>
//         <IconButton onClick={() => setIsModalOpen(true)}>
//           <Settings />
//         </IconButton>
//       </Box>

//       {/* Прогресс */}
//       <Box sx={{ textAlign: 'center', mt: 0 }}>
//         <Typography variant="h6">
//           Progress: {progressText}
//         </Typography>
//       </Box>

//       <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
//         <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             Settings
//           </Typography>
//           <TextField
//             label="Interval (ms)"
//             type="number"
//             value={intervalTime}
//             onChange={(e) => setIntervalTime(Number(e.target.value))}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Font size (rem)"
//             type="number"
//             value={fontSize}
//             onChange={(e) => setFontSize(Number(e.target.value))}
//             fullWidth
//             margin="normal"
//           />
//           <FormControlLabel
//             control={<Switch checked={playAudio} onChange={(e) => setPlayAudio(e.target.checked)} />}
//             label="Play audio"
//           />
//           <FormControlLabel
//             control={<Switch checked={positionMode === 'random'} onChange={(e) => setPositionMode(e.target.checked ? 'random' : 'center')} />}
//             label="Random position"
//           />
//           <FormControlLabel
//             control={<Switch checked={theme === 'dark'} onChange={() => dispatch(toggleTheme())} />}
//             label="Dark Theme"
//           />
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button onClick={() => setIsModalOpen(false)}>Close</Button>
//           </Box>
//         </Box>
//       </Modal>

//       <Card
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '80vh',
//           borderRadius: '10px',
//           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//           position: 'relative',
//           overflow: 'hidden',
//           width: '100%',
//           maxWidth: 'none',
//           padding: 0,
//         }}
//       >
//         {trainingData.length > 0 ? (
//           <Box
//             ref={textContainerRef}
//             sx={{
//               textAlign: 'center',
//               position: 'absolute',
//               width: '80%',
//               maxWidth: '80%',
//               wordWrap: 'break-word',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'center',
//               alignItems: 'center',
//               padding: '20px',
//               boxSizing: 'border-box',
//               left: randomPosition.left,
//               top: randomPosition.top,
//               transform: 'translate(-50%, -50%)',
//             }}
//           >
//             <Typography sx={{ fontSize: `${fontSize}rem`, marginBottom: '10px' }}>
//               {isWordsMode
//                 ? trainingData[currentSuggestiveIndex].text
//                 : trainingData[currentSuggestiveIndex].text}
//             </Typography>
//             <Typography sx={{ fontSize: `${fontSize}rem` }}>
//               {isWordsMode
//                 ? trainingData[currentSuggestiveIndex].translation
//                 : trainingData[currentSuggestiveIndex].translation}
//             </Typography>
//           </Box>
//         ) : (
//           <Typography variant="body1" align="center" sx={{ fontSize: `${fontSize}rem` }}>
//             No data available for training.
//           </Typography>
//         )}
//       </Card>
//     </Box>
//   );
// };

// export default SuggestiveTrainingPage;





// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { transformLang } from './Utils';
// import { convert } from 'html-to-text';
// import { Button, Card, Typography, IconButton, Box, Modal, TextField, Switch, FormControlLabel } from '@mui/material';
// import { ArrowBack, Settings } from '@mui/icons-material';
// import { toggleTheme } from '../redux/themeSlice';
// import { synthesizeSpeech } from '../redux/ttsSlice'; // Импортируем действие из слайса
// import './Flashcard.css';

// const SuggestiveTrainingPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.auth.user);
//   const theme = useSelector((state) => state.theme.theme);
//   const { audioUrl, isLoading: isTtsLoading, error: ttsError } = useSelector((state) => state.tts); // Состояние TTS
//   const { sentences, words, isWordsMode } = location.state || {};
//   const [currentSuggestiveIndex, setCurrentSuggestiveIndex] = useState(0);
//   const [randomPosition, setRandomPosition] = useState({ top: '50%', left: '50%' });
//   const [intervalTime, setIntervalTime] = useState(10000);
//   const [positionMode, setPositionMode] = useState('center');
//   const [fontSize, setFontSize] = useState(1.5);
//   const [playAudio, setPlayAudio] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const animationFrameRef = useRef(null);
//   const textContainerRef = useRef(null);
//   const sortedSentences = sentences?.sort((a, b) => a.order - b.order) || [];
//   const trainingData = isWordsMode ? words : sortedSentences || [];
//   const totalItems = trainingData.length; // Общее количество элементов
//   const progressText = `${currentSuggestiveIndex + 1}/${totalItems}`; // Текст прогресса

//   useEffect(() => {
//     if (trainingData.length === 0) return;

//     let startTime = Date.now();

//     const animate = () => {
//       const currentTime = Date.now();
//       if (currentTime - startTime >= intervalTime) {
//         startTime = currentTime;
//         setCurrentSuggestiveIndex((prevIndex) => {
//           if (prevIndex >= trainingData.length - 1) {
//             return 0; // Сбрасываем индекс, если достигли конца списка
//           }
//           return prevIndex + 1;
//         });

//         if (positionMode === 'random') {
//           const textContainer = textContainerRef.current;
//           if (textContainer) {
//             const parentRect = textContainer.parentElement.getBoundingClientRect();
//             const textRect = textContainer.getBoundingClientRect();

//             const maxTop = parentRect.height - textRect.height;
//             const maxLeft = parentRect.width - textRect.width;

//             const randomTop = Math.max(0, Math.min(maxTop, Math.random() * maxTop));
//             const randomLeft = Math.max(0, Math.min(maxLeft, Math.random() * maxLeft));

//             setRandomPosition({
//               top: `${(randomTop / parentRect.height) * 100}%`,
//               left: `${(randomLeft / parentRect.width) * 100}%`,
//             });
//           }
//         } else {
//           setRandomPosition({ top: '50%', left: '50%' });
//         }
//       }

//       animationFrameRef.current = requestAnimationFrame(animate);
//     };

//     animationFrameRef.current = requestAnimationFrame(animate);

//     return () => cancelAnimationFrame(animationFrameRef.current);
//   }, [trainingData, intervalTime, positionMode]);

//   useEffect(() => {
//     if (trainingData.length === 0) return;

//     if (playAudio && trainingData[currentSuggestiveIndex]) {
//       const text = isWordsMode ? trainingData[currentSuggestiveIndex].text : trainingData[currentSuggestiveIndex].text;
//       handlePlay(text);
//     }
//   }, [currentSuggestiveIndex, playAudio, trainingData, isWordsMode]);

//   const handlePlay = async (text) => {
//     if (!user || !user.text_language) {
//       console.error("User data is not available or text_language is missing.");
//       return;
//     }

//     const cleanText = stripHtmlTags(text);
//     console.log(cleanText);

//     // Проверяем, запущено ли приложение в Telegram
//     if (window.Telegram && window.Telegram.WebApp) {
//       // Используем Redux для синтеза речи
//       dispatch(synthesizeSpeech({ text: cleanText, lang: transformLang(user.text_language) }));
//     } else if (window.ReactNativeWebView) {
//       // Для React Native WebView
//       window.ReactNativeWebView.postMessage(JSON.stringify({
//         type: 'speak',
//         lang: transformLang(user.text_language),
//         text: cleanText,
//         comp: 'SentenceList',
//         voice: user.text_voice
//       }));
//     } else {
//       // Используем стандартный SpeechSynthesis
//       const newUtterance = new SpeechSynthesisUtterance(cleanText);
//       newUtterance.lang = transformLang(user.text_language);
//       speechSynthesis.speak(newUtterance);
//     }
//   };

//   // Воспроизведение аудио, если URL доступен
//   useEffect(() => {
//     if (audioUrl) {
//       const audio = new Audio(audioUrl);
//       audio.play();
//     }
//   }, [audioUrl]);

//   const stripHtmlTags = (html) => {
//     return convert(html, {
//       formatters: {
//         'fooBlockFormatter': function (elem, walk, builder, formatOptions) {
//           builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
//           walk(elem.children, builder);
//           builder.addInline('!');
//           builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
//         }
//       },
//       selectors: [
//         {
//           selector: 'foo',
//           format: 'fooBlockFormatter',
//           options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
//         }
//       ]
//     });
//   };

//   if (trainingData.length === 0) {
//     return (
//       <Box sx={{ padding: 2 }}>
//         <IconButton onClick={() => navigate(-1)}>
//           <ArrowBack />
//         </IconButton>
//         <Typography variant="h4" align="center" gutterBottom>
//           Training
//         </Typography>
//         <Typography variant="body1" align="center">
//           No data available for training.
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <IconButton onClick={() => navigate(-1)}>
//           <ArrowBack />
//         </IconButton>
//         <IconButton onClick={() => setIsModalOpen(true)}>
//           <Settings />
//         </IconButton>
//       </Box>

//       {/* Прогресс */}
//       <Box sx={{ textAlign: 'center', mt: 0 }}>
//         <Typography variant="h6">
//           Progress: {progressText}
//         </Typography>
//       </Box>

//       <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
//         <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
//           <Typography variant="h6" gutterBottom>
//             Settings
//           </Typography>
//           <TextField
//             label="Interval (ms)"
//             type="number"
//             value={intervalTime}
//             onChange={(e) => setIntervalTime(Number(e.target.value))}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Font size (rem)"
//             type="number"
//             value={fontSize}
//             onChange={(e) => setFontSize(Number(e.target.value))}
//             fullWidth
//             margin="normal"
//           />
//           <FormControlLabel
//             control={<Switch checked={playAudio} onChange={(e) => setPlayAudio(e.target.checked)} />}
//             label="Play audio"
//           />
//           <FormControlLabel
//             control={<Switch checked={positionMode === 'random'} onChange={(e) => setPositionMode(e.target.checked ? 'random' : 'center')} />}
//             label="Random position"
//           />
//           <FormControlLabel
//             control={<Switch checked={theme === 'dark'} onChange={() => dispatch(toggleTheme())} />}
//             label="Dark Theme"
//           />
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button onClick={() => setIsModalOpen(false)}>Close</Button>
//           </Box>
//         </Box>
//       </Modal>

//       <Card
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '80vh',
//           borderRadius: '10px',
//           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//           position: 'relative',
//           overflow: 'hidden',
//           width: '100%',
//           maxWidth: 'none',
//           padding: 0,
//         }}
//       >
//         {trainingData.length > 0 ? (
//           <Box
//             ref={textContainerRef}
//             sx={{
//               textAlign: 'center',
//               position: 'absolute',
//               width: '80%',
//               maxWidth: '80%',
//               wordWrap: 'break-word',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'center',
//               alignItems: 'center',
//               padding: '20px',
//               boxSizing: 'border-box',
//               left: randomPosition.left,
//               top: randomPosition.top,
//               transform: 'translate(-50%, -50%)',
//             }}
//           >
//             <Typography sx={{ fontSize: `${fontSize}rem`, marginBottom: '10px' }}>
//               {isWordsMode
//                 ? trainingData[currentSuggestiveIndex].text
//                 : trainingData[currentSuggestiveIndex].text}
//             </Typography>
//             <Typography sx={{ fontSize: `${fontSize}rem` }}>
//               {isWordsMode
//                 ? trainingData[currentSuggestiveIndex].translation
//                 : trainingData[currentSuggestiveIndex].translation}
//             </Typography>
//           </Box>
//         ) : (
//           <Typography variant="body1" align="center" sx={{ fontSize: `${fontSize}rem` }}>
//             No data available for training.
//           </Typography>
//         )}
//       </Card>
//     </Box>
//   );
// };

// export default SuggestiveTrainingPage;












import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { transformLang } from './Utils';
import { convert } from 'html-to-text';
import { Button, Card, Typography, IconButton, Box, Modal, TextField, Switch, FormControlLabel } from '@mui/material';
import { ArrowBack, Settings, PlayArrow, Pause } from '@mui/icons-material';
import { toggleTheme } from '../redux/themeSlice';
import { synthesizeSpeech, clearAudioUrl } from '../redux/ttsSlice'; // Импортируем действие для очистки audioUrl
import { isTelegramWebApp } from './Utils'; // Импортируем функцию
import './Flashcard.css';

const SuggestiveTrainingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const theme = useSelector((state) => state.theme.theme);
  const { audioUrl, isLoading: isTtsLoading, error: ttsError } = useSelector((state) => state.tts); // Состояние TTS
  const { sentences, words, isWordsMode } = location.state || {};
  const [currentSuggestiveIndex, setCurrentSuggestiveIndex] = useState(0);
  const [randomPosition, setRandomPosition] = useState({ top: '50%', left: '50%' });
  const [intervalTime, setIntervalTime] = useState(10000);
  const [positionMode, setPositionMode] = useState('center');
  const [fontSize, setFontSize] = useState(1.5);
  const [playAudio, setPlayAudio] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTrainingActive, setIsTrainingActive] = useState(false); // Состояние для отслеживания активности тренировки
  const [isAudioPlaying, setIsAudioPlaying] = useState(false); // Состояние для отслеживания воспроизведения аудио

  const animationFrameRef = useRef(null);
  const textContainerRef = useRef(null);
  const audioRef = useRef(null); // Ref для управления аудио
  const sortedSentences = sentences?.sort((a, b) => a.order - b.order) || [];
  const trainingData = isWordsMode ? words : sortedSentences || [];
  const totalItems = trainingData.length; // Общее количество элементов
  const progressText = `${currentSuggestiveIndex + 1}/${totalItems}`; // Текст прогресса

  useEffect(() => {
    if (!isTrainingActive || trainingData.length === 0) return;

    let startTime = Date.now();

    const animate = () => {
      const currentTime = Date.now();
      if (currentTime - startTime >= intervalTime && !isAudioPlaying) { // Проверяем, не воспроизводится ли аудио
        startTime = currentTime;
        setCurrentSuggestiveIndex((prevIndex) => {
          if (prevIndex >= trainingData.length - 1) {
            return 0; // Сбрасываем индекс, если достигли конца списка
          }
          return prevIndex + 1;
        });

        if (positionMode === 'random') {
          const textContainer = textContainerRef.current;
          if (textContainer) {
            const parentRect = textContainer.parentElement.getBoundingClientRect();
            const textRect = textContainer.getBoundingClientRect();

            const maxTop = parentRect.height - textRect.height;
            const maxLeft = parentRect.width - textRect.width;

            const randomTop = Math.max(0, Math.min(maxTop, Math.random() * maxTop));
            const randomLeft = Math.max(0, Math.min(maxLeft, Math.random() * maxLeft));

            setRandomPosition({
              top: `${(randomTop / parentRect.height) * 100}%`,
              left: `${(randomLeft / parentRect.width) * 100}%`,
            });
          }
        } else {
          setRandomPosition({ top: '50%', left: '50%' });
        }
      }

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [trainingData, intervalTime, positionMode, isTrainingActive, isAudioPlaying]);

  useEffect(() => {
    if (trainingData.length === 0) return;

    if (playAudio && trainingData[currentSuggestiveIndex]) {
      const text = isWordsMode ? trainingData[currentSuggestiveIndex].text : trainingData[currentSuggestiveIndex].text;
      handlePlay(text);
    }
  }, [currentSuggestiveIndex, playAudio, trainingData, isWordsMode]);


  const handlePlay = async (text) => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }
  
    const cleanText = stripHtmlTags(text);
    console.log(cleanText);
  
    // Проверяем, запущено ли приложение в Telegram
    if (isTelegramWebApp()) {
      // Используем Redux для синтеза речи
      dispatch(synthesizeSpeech({ text: cleanText, lang: transformLang(user.text_language) }));
    } else if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      // Для React Native WebView
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'speak',
        lang: transformLang(user.text_language),
        text: cleanText,
        comp: 'SentenceList',
        voice: user.text_voice
      }));
    } else if (typeof window !== 'undefined' && 'speechSynthesis' in window) {
      // Используем стандартный SpeechSynthesis в браузере
      const newUtterance = new SpeechSynthesisUtterance(cleanText);
      newUtterance.lang = transformLang(user.text_language);
      speechSynthesis.speak(newUtterance);
    }
  };  

  // Воспроизведение аудио, если URL доступен
  useEffect(() => {
    if (audioUrl) {
      setIsAudioPlaying(true); // Устанавливаем состояние воспроизведения аудио
      const audio = new Audio(audioUrl);
      audioRef.current = audio; // Сохраняем ссылку на аудио
      audio.play();

      // Обработчик завершения воспроизведения
      audio.addEventListener('ended', () => {
        setIsAudioPlaying(false); // Сбрасываем состояние воспроизведения аудио
        dispatch(clearAudioUrl()); // Очищаем audioUrl
      });
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause(); // Останавливаем воспроизведение при размонтировании
        audioRef.current.removeEventListener('ended', () => {}); // Удаляем обработчик
      }
    };
  }, [audioUrl, dispatch]);

  const stripHtmlTags = (html) => {
    return convert(html, {
      formatters: {
        'fooBlockFormatter': function (elem, walk, builder, formatOptions) {
          builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
          walk(elem.children, builder);
          builder.addInline('!');
          builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
        }
      },
      selectors: [
        {
          selector: 'foo',
          format: 'fooBlockFormatter',
          options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
        }
      ]
    });
  };

  if (trainingData.length === 0) {
    return (
      <Box sx={{ padding: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          Training
        </Typography>
        <Typography variant="body1" align="center">
          No data available for training.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <Settings />
        </IconButton>
      </Box>

      {/* Прогресс */}
      <Box sx={{ textAlign: 'center', mt: 0 }}>
        <Typography variant="h6">
          Progress: {progressText}
        </Typography>
      </Box>

      {/* Кнопка запуска/паузы */}
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <IconButton onClick={() => setIsTrainingActive(!isTrainingActive)}>
          {isTrainingActive ? <Pause /> : <PlayArrow />}
        </IconButton>
      </Box>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" gutterBottom>
            Settings
          </Typography>
          <TextField
            label="Interval (ms)"
            type="number"
            value={intervalTime}
            onChange={(e) => setIntervalTime(Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Font size (rem)"
            type="number"
            value={fontSize}
            onChange={(e) => setFontSize(Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={<Switch checked={playAudio} onChange={(e) => setPlayAudio(e.target.checked)} />}
            label="Play audio"
          />
          <FormControlLabel
            control={<Switch checked={positionMode === 'random'} onChange={(e) => setPositionMode(e.target.checked ? 'random' : 'center')} />}
            label="Random position"
          />
          <FormControlLabel
            control={<Switch checked={theme === 'dark'} onChange={() => dispatch(toggleTheme())} />}
            label="Dark Theme"
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          </Box>
        </Box>
      </Modal>

      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          maxWidth: 'none',
          padding: 0,
        }}
      >
        {trainingData.length > 0 ? (
          <Box
            ref={textContainerRef}
            sx={{
              textAlign: 'center',
              position: 'absolute',
              width: '80%',
              maxWidth: '80%',
              wordWrap: 'break-word',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
              boxSizing: 'border-box',
              left: randomPosition.left,
              top: randomPosition.top,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography sx={{ fontSize: `${fontSize}rem`, marginBottom: '10px' }}>
              {isWordsMode
                ? trainingData[currentSuggestiveIndex].text
                : trainingData[currentSuggestiveIndex].text}
            </Typography>
            <Typography sx={{ fontSize: `${fontSize}rem` }}>
              {isWordsMode
                ? trainingData[currentSuggestiveIndex].translation
                : trainingData[currentSuggestiveIndex].translation}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1" align="center" sx={{ fontSize: `${fontSize}rem` }}>
            No data available for training.
          </Typography>
        )}
      </Card>
    </Box>
  );
};

export default SuggestiveTrainingPage;
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchUserExpressions, addUserExpression, deleteUserExpression, fetchThemes } from '../redux/textsSlice';
// import api from '../axios';
// import {
//   Modal,
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   Alert,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   Typography,
//   Box,
//   FormControl,
//   InputLabel,
//   CircularProgress,
//   Paper,
//   Collapse,
//   Chip,
//   SpeedDial,
//   SpeedDialIcon,
//   SpeedDialAction,
//   Grid,
//   Divider, // Добавлен Divider
// } from '@mui/material';
// import {
//   ExpandMore,
//   VolumeUp,
//   Edit,
//   Delete,
//   Visibility,
//   ArrowBack,
//   Book,
//   LightMode,
//   Add,
//   FileUpload,
//   Quiz,
// } from '@mui/icons-material';
// import { transformLang } from './Utils';

// const UserExpressionsList = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { userExpressions, loading, error, themes } = useSelector((state) => state.texts);
//   const courseId = useSelector((state) => state.texts.courseId);
//   const [selectedExpression, setSelectedExpression] = useState(null);
//   const [newExpression, setNewExpression] = useState('');
//   const [newTranslation, setNewTranslation] = useState('');
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [showCreateModal, setShowCreateModal] = useState(false);
//   const [showAudioRecognitionModal, setShowAudioRecognitionModal] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [selectedTheme, setSelectedTheme] = useState(null);
//   const [audioRecognitionInput, setAudioRecognitionInput] = useState('');
//   const [showTranslations, setShowTranslations] = useState({});
//   const [expandedThemes, setExpandedThemes] = useState({});
//   const [showUploadModal, setShowUploadModal] = useState(false);
//   const [file, setFile] = useState(null);
//   const [delimiter, setDelimiter] = useState(',');
//   const [openSpeedDial, setOpenSpeedDial] = useState(false);

//   const user = useSelector((state) => state.auth.user);
//   const themeMode = useSelector((state) => state.theme.theme);

//   useEffect(() => {
//     if (courseId) {
//       dispatch(fetchUserExpressions({ courseId }));
//       dispatch(fetchThemes(courseId));
//     }
//   }, [dispatch, courseId]);

//   const toggleTheme = (themeId) => {
//     setExpandedThemes((prev) => ({
//       ...prev,
//       [themeId]: !prev[themeId],
//     }));
//   };

//   const handleThemeSelect = (themeId) => {
//     setSelectedTheme(themeId);
//   };

//   const handleExpressionClick = (expression) => {
//     setSelectedExpression(expression);
//     setShowEditModal(true);
//   };

//   const handleExpressionUpdate = (expressionId, updatedData) => {
//     api
//       .put(`/user-expressions/${expressionId}/`, updatedData)
//       .then((response) => {
//         dispatch(fetchUserExpressions({ courseId }));
//         setSelectedExpression(null);
//         setShowEditModal(false);
//       })
//       .catch((error) => console.error('Error updating expression:', error));
//   };

//   const handleExpressionDelete = (expressionId) => {
//     api
//       .delete(`/user-expressions/${expressionId}/`)
//       .then(() => {
//         dispatch(fetchUserExpressions({ courseId }));
//         setSelectedExpression(null);
//         setShowEditModal(false);
//       })
//       .catch((error) => console.error('Error deleting expression:', error));
//   };

//   const handleCreateExpression = () => {
//     if (!courseId) {
//       alert('Please select a course first.');
//       return;
//     }

//     const newExpressionData = {
//       text: newExpression,
//       translation: newTranslation,
//       theme: selectedTheme,
//       course_id: courseId,
//     };

//     dispatch(addUserExpression(newExpressionData))
//       .then(() => {
//         dispatch(fetchUserExpressions({ courseId }));
//         setNewExpression('');
//         setNewTranslation('');
//         setShowCreateModal(false);
//       })
//       .catch((error) => console.error('Error creating expression:', error));
//   };

//   const handlePlayAudio = (text, lang, voice) => {
//     const lang_tr = transformLang(lang);
//     if (window.ReactNativeWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text: text, lang: lang_tr, comp: ' ', voice: voice }));
//     } else {
//       const msg = new SpeechSynthesisUtterance(text);
//       msg.lang = lang_tr;
//       window.speechSynthesis.speak(msg);
//     }
//   };

//   const handleCheckAudioRecognition = () => {
//     const expressionsForTheme = userExpressions.filter((expression) => expression.theme === selectedTheme);
//     const currentExpression = expressionsForTheme[0];

//     if (!currentExpression) {
//       setMessage({ variant: 'error', text: 'No expression found!' });
//       return;
//     }

//     if (audioRecognitionInput.toLowerCase() === currentExpression.text.toLowerCase()) {
//       setMessage({ variant: 'success', text: 'Correct!' });
//     } else {
//       setMessage({ variant: 'error', text: 'Incorrect!' });
//     }
//     setAudioRecognitionInput('');
//   };

//   const toggleTranslation = (expressionId) => {
//     setShowTranslations({
//       ...showTranslations,
//       [expressionId]: !showTranslations[expressionId],
//     });
//   };

//   const handleFlashcards = () => {
//     if (!selectedTheme) {
//       setMessage({ variant: 'error', text: 'Please select a theme first!' });
//       return;
//     }

//     const expressions = userExpressions.filter((expression) => expression.theme === selectedTheme);
//     const sentences = expressions.map((expression) => ({
//       text: expression.text,
//       translation: expression.translation,
//     }));

//     navigate('/flashcards', { state: { sentences } });
//   };

//   const handleSuggestiveTraining = () => {
//     if (!selectedTheme) {
//       setMessage({ variant: 'error', text: 'Please select a theme first!' });
//       return;
//     }

//     const expressions = userExpressions.filter((expression) => expression.theme === selectedTheme);
//     const sentences = expressions.map((expression) => ({
//       text: expression.text,
//       translation: expression.translation,
//     }));

//     navigate('/suggestive-training', { state: { sentences } });
//   };

//   const handleCrossword = () => {
//     if (!selectedTheme) {
//       setMessage({ variant: 'error', text: 'Please select a theme first!' });
//       return;
//     }

//     const expressions = userExpressions.filter((expression) => expression.theme === selectedTheme);
//     const words = expressions.map((expression) => ({
//       text: expression.text,
//       translation: expression.translation,
//     }));

//     navigate('/crossword', { state: { words } });
//   };

//   const handleFileUpload = () => {
//     if (!file || !selectedTheme) {
//       setMessage({ variant: 'error', text: 'Please select a file and a theme.' });
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('theme', selectedTheme);
//     formData.append('course_id', courseId);
//     formData.append('delimiter', delimiter);

//     api
//       .post('/user-expressions/upload-csv/', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       })
//       .then((response) => {
//         dispatch(fetchUserExpressions({ courseId }));
//         setMessage({ variant: 'success', text: 'Expressions uploaded successfully!' });
//         setShowUploadModal(false);
//       })
//       .catch((error) => {
//         console.error('Error uploading expressions:', error);
//         setMessage({ variant: 'error', text: 'Error uploading expressions. Please try again.' });
//       });
//   };

//   const actions = [
//     { icon: <Add />, name: 'Create Expression', onClick: () => setShowCreateModal(true) },
//     { icon: <FileUpload />, name: 'Upload CSV', onClick: () => setShowUploadModal(true) },
//   ];

//   return (
//     <Box sx={{ padding: 0 }}>
//       {message && (
//         <Alert severity={message.variant} onClose={() => setMessage(null)} sx={{ mb: 2 }}>
//           {message.text}
//         </Alert>
//       )}

//       <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//         <IconButton onClick={() => navigate(-1)}>
//           <ArrowBack />
//         </IconButton>
//         <Typography variant="h5" sx={{ ml: 2 }}>
//           User Expressions
//         </Typography>
//       </Box>

//       {/* Кнопки тренировок с использованием IconButton */}
//       <Grid container spacing={2} sx={{ mb: 3, justifyContent: 'space-between' }}>
//         <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
//           <IconButton onClick={handleFlashcards} color="primary" sx={{ flexDirection: 'column' }}>
//             <Book fontSize="large" />
//             <Typography variant="body2">Flashcards</Typography>
//           </IconButton>
//         </Grid>
//         <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
//           <IconButton onClick={handleSuggestiveTraining} color="primary" sx={{ flexDirection: 'column' }}>
//             <LightMode fontSize="large" />
//             <Typography variant="body2">Training</Typography>
//           </IconButton>
//         </Grid>
//         <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
//           <IconButton onClick={handleCrossword} color="primary" sx={{ flexDirection: 'column' }}>
//             <Quiz fontSize="large" />
//             <Typography variant="body2">Crossword</Typography>
//           </IconButton>
//         </Grid>
//       </Grid>

//       {/* Вложенный список: темы и выражения */}
//       <List>
//         {themes.map((theme) => {
//           const expressionsCount = userExpressions.filter((expression) => expression.theme === theme.id).length;
//           return (
//             <Paper
//               key={theme.id}
//               sx={{
//                 mb: 2,
//                 p: 2,
//                 backgroundColor: themeMode === "dark" ? "#1e1e1e" : "#ffffff",
//                 color: themeMode === "dark" ? "#e0e0e0" : "#000000",
//                 boxShadow: themeMode === "dark" ? "0px 2px 4px rgba(0, 0, 0, 0.5)" : "0px 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <ListItem
//                 button
//                 onClick={() => {
//                   toggleTheme(theme.id);
//                   handleThemeSelect(theme.id);
//                 }}
//                 selected={selectedTheme === theme.id}
//               >
//                 <ListItemText
//                   primary={
//                     <Typography variant="p">
//                       {theme.name} <Chip label={expressionsCount} size="small" color="primary" sx={{ ml: 1 }} />
//                     </Typography>
//                   }
//                 />
//                 <IconButton>
//                   <ExpandMore />
//                 </IconButton>
//               </ListItem>
//               <Collapse in={expandedThemes[theme.id]}>
//                 <List>
//                   {userExpressions
//                     .filter((expression) => expression.theme === theme.id)
//                     .map((expression, index, array) => (
//                       <React.Fragment key={expression.id}>
//                         <ListItem
//                           sx={{ flexDirection: 'column', alignItems: 'flex-start', pl: 4, mb: 1 }}
//                         >
//                           <Typography variant="body1">{expression.text}</Typography>
//                           {showTranslations[expression.id] && (
//                             <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
//                               {expression.translation}
//                             </Typography>
//                           )}
//                           <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
//                             <IconButton
//                               onClick={() => handlePlayAudio(expression.text, user.text_language, user.text_voice)}
//                               size="small"
//                               sx={{ padding: '4px' }}
//                               color="primary"
//                             >
//                               <VolumeUp fontSize="small" />
//                             </IconButton>
//                             <IconButton
//                               onClick={() => handleExpressionClick(expression)}
//                               size="small"
//                               sx={{ padding: '4px' }}
//                               color="secondary"
//                             >
//                               <Edit fontSize="small" />
//                             </IconButton>
//                             <IconButton
//                               onClick={() => handleExpressionDelete(expression.id)}
//                               size="small"
//                               sx={{ padding: '4px' }}
//                               color="error"
//                             >
//                               <Delete fontSize="small" />
//                             </IconButton>
//                             <IconButton
//                               onClick={() => toggleTranslation(expression.id)}
//                               size="small"
//                               sx={{ padding: '4px' }}
//                               color="info"
//                             >
//                               <Visibility fontSize="small" />
//                             </IconButton>
//                           </Box>
//                         </ListItem>
//                         {index < array.length - 1 && <Divider />} {/* Добавлен Divider */}
//                       </React.Fragment>
//                     ))}
//                 </List>
//               </Collapse>
//             </Paper>
//           );
//         })}
//       </List>

//       {/* Модальное окно для редактирования выражения */}
//       <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
//         <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Edit Expression
//           </Typography>
//           {selectedExpression && (
//             <Box component="form">
//               <TextField
//                 fullWidth
//                 label="Expression"
//                 value={selectedExpression.text}
//                 onChange={(e) => setSelectedExpression({ ...selectedExpression, text: e.target.value })}
//                 sx={{ mb: 2 }}
//               />
//               <TextField
//                 fullWidth
//                 label="Translation"
//                 value={selectedExpression.translation || ''}
//                 onChange={(e) => setSelectedExpression({ ...selectedExpression, translation: e.target.value })}
//                 sx={{ mb: 2 }}
//               />
//             </Box>
//           )}
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
//             <Button onClick={() => setShowEditModal(false)} variant="outlined">
//               Close
//             </Button>
//             <Button onClick={() => handleExpressionUpdate(selectedExpression.id, selectedExpression)} variant="contained">
//               Save Changes
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       {/* Модальное окно для создания нового выражения */}
//       <Modal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
//         <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Create New Expression
//           </Typography>
//           <Box component="form">
//             <TextField
//               fullWidth
//               label="Expression"
//               value={newExpression}
//               onChange={(e) => setNewExpression(e.target.value)}
//               sx={{ mb: 2 }}
//             />
//             <TextField
//               fullWidth
//               label="Translation"
//               value={newTranslation}
//               onChange={(e) => setNewTranslation(e.target.value)}
//               sx={{ mb: 2 }}
//             />
//             <FormControl fullWidth sx={{ mb: 2 }}>
//               <InputLabel>Theme</InputLabel>
//               <Select value={selectedTheme || ''} onChange={(e) => setSelectedTheme(e.target.value)}>
//                 <MenuItem value="">Select Theme</MenuItem>
//                 {themes.map((theme) => (
//                   <MenuItem key={theme.id} value={theme.id}>
//                     {theme.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
//             <Button onClick={() => setShowCreateModal(false)} variant="outlined">
//               Close
//             </Button>
//             <Button onClick={handleCreateExpression} variant="contained">
//               Create Expression
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       {/* Модальное окно для загрузки CSV-файла */}
//       <Modal open={showUploadModal} onClose={() => setShowUploadModal(false)}>
//         <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Upload Expressions from CSV
//           </Typography>
//           <Box component="form">
//             <input
//               type="file"
//               accept=".csv"
//               onChange={(e) => setFile(e.target.files[0])}
//               style={{ marginBottom: '16px' }}
//             />
//             <FormControl fullWidth sx={{ mb: 2 }}>
//               <InputLabel>Theme</InputLabel>
//               <Select value={selectedTheme || ''} onChange={(e) => setSelectedTheme(e.target.value)}>
//                 <MenuItem value="">Select Theme</MenuItem>
//                 {themes.map((theme) => (
//                   <MenuItem key={theme.id} value={theme.id}>
//                     {theme.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <FormControl fullWidth sx={{ mb: 2 }}>
//               <InputLabel>Delimiter</InputLabel>
//               <Select value={delimiter} onChange={(e) => setDelimiter(e.target.value)}>
//                 <MenuItem value=",">Comma (,)</MenuItem>
//                 <MenuItem value="\t">Tab (\t)</MenuItem>
//                 <MenuItem value="#">Hash (#)</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
//             <Button onClick={() => setShowUploadModal(false)} variant="outlined">
//               Close
//             </Button>
//             <Button onClick={handleFileUpload} variant="contained">
//               Upload
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       {/* SpeedDial для создания выражения и загрузки CSV */}
//       <SpeedDial
//         ariaLabel="SpeedDial"
//         sx={{ position: 'fixed', bottom: 80, right: 16 }}
//         icon={<SpeedDialIcon />}
//         onClose={() => setOpenSpeedDial(false)}
//         onOpen={() => setOpenSpeedDial(true)}
//         open={openSpeedDial}
//       >
//         {actions.map((action) => (
//           <SpeedDialAction
//             key={action.name}
//             icon={action.icon}
//             tooltipTitle={action.name}
//             onClick={action.onClick}
//           />
//         ))}
//       </SpeedDial>
//     </Box>
//   );
// };

// export default UserExpressionsList;



import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  fetchUserExpressions, 
  addUserExpression, 
  deleteUserExpression, 
  fetchThemes, 
  fetchExpressionsByTheme, 
  fetchExpressionsCountByTheme 
} from '../redux/textsSlice';
import api from '../axios';
import {
  Modal,
  Button,
  TextField,
  Select,
  MenuItem,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  FormControl,
  InputLabel,
  CircularProgress,
  Paper,
  Collapse,
  Chip,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Divider,
  Pagination,
} from '@mui/material';
import {
  ExpandMore,
  VolumeUp,
  Edit,
  Delete,
  Visibility,
  ArrowBack,
  Book,
  LightMode,
  Add,
  FileUpload,
  Quiz,
} from '@mui/icons-material';
import { transformLang } from './Utils';
import { synthesizeSpeech, clearAudioUrl } from '../redux/ttsSlice'; // Импортируем действие из слайса

const UserExpressionsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userExpressions, loading, error, themes } = useSelector((state) => state.texts);
  const courseId = useSelector((state) => state.texts.courseId);
  const [selectedExpression, setSelectedExpression] = useState(null);
  const [newExpression, setNewExpression] = useState('');
  const [newTranslation, setNewTranslation] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showAudioRecognitionModal, setShowAudioRecognitionModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [audioRecognitionInput, setAudioRecognitionInput] = useState('');
  const [showTranslations, setShowTranslations] = useState({});
  const [expandedThemes, setExpandedThemes] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState(',');
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [loadedThemes, setLoadedThemes] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [expressionsCountByTheme, setExpressionsCountByTheme] = useState({});
  const user = useSelector((state) => state.auth.user);
  const themeMode = useSelector((state) => state.theme.theme);
  const { audioUrl, isLoading: isTtsLoading, error: ttsError } = useSelector((state) => state.tts); // Состояние TTS
  const [isTrainingActive, setIsTrainingActive] = useState(false); // Состояние для отслеживания активности тренировки
  const [isAudioPlaying, setIsAudioPlaying] = useState(false); // Состояние для отслеживания воспроизведения аудио

  const audioRef = useRef(null); // Ref для управления аудио



  //const [expressionsCountByTheme, setExpressionsCountByTheme] = useState({});

  useEffect(() => {
    if (courseId) {
      dispatch(fetchThemes(courseId)); // Загружаем темы
      dispatch(fetchExpressionsCountByTheme(courseId)).then((response) => {
        setExpressionsCountByTheme(response.payload); // Сохраняем количество выражений
      });
    }
  }, [dispatch, courseId]);


const toggleTheme = (themeId) => {
    if (!loadedThemes[themeId]) {
      dispatch(fetchExpressionsByTheme({ themeId, page: 1 })).then((response) => {
        setLoadedThemes((prev) => ({
          ...prev,
          [themeId]: true,
        }));
        setExpressionsCountByTheme((prev) => ({
          ...prev,
          [themeId]: response.payload.count, // Обновляем количество выражений
        }));
        setTotalPages(Math.ceil(response.payload.count / 10)); // Общее количество страниц
      });
    }

    setExpandedThemes((prev) => ({
      ...prev,
      [themeId]: !prev[themeId],
    }));
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    dispatch(fetchExpressionsByTheme({ themeId: selectedTheme, page })).then((response) => {
      setExpressionsCountByTheme((prev) => ({
        ...prev,
        [selectedTheme]: response.payload.count, // Обновляем общее количество выражений
      }));
      setTotalPages(Math.ceil(response.payload.count / 10)); // Обновляем общее количество страниц
    });
  };
  
  const handleThemeSelect = (themeId) => {
    setSelectedTheme(themeId);
  };

  const handleExpressionClick = (expression) => {
    setSelectedExpression(expression);
    setShowEditModal(true);
  };

  const handleExpressionUpdate = (expressionId, updatedData) => {
    api
      .put(`/user-expressions/${expressionId}/`, updatedData)
      .then(() => {
        dispatch(fetchExpressionsByTheme({ themeId: selectedTheme, page: currentPage })).then((response) => {
          setExpressionsCountByTheme((prev) => ({
            ...prev,
            [selectedTheme]: response.payload.count, // Обновляем общее количество выражений
          }));
          setTotalPages(Math.ceil(response.payload.count / 10)); // Обновляем общее количество страниц
        });
        setSelectedExpression(null);
        setShowEditModal(false);
      })
      .catch((error) => console.error('Error updating expression:', error));
  };

  const handleExpressionDelete = (expressionId) => {
    api
      .delete(`/user-expressions/${expressionId}/`)
      .then(() => {
        dispatch(fetchExpressionsByTheme({ themeId: selectedTheme, page: currentPage })).then((response) => {
          setExpressionsCountByTheme((prev) => ({
            ...prev,
            [selectedTheme]: response.payload.count, // Обновляем общее количество выражений
          }));
          setTotalPages(Math.ceil(response.payload.count / 10)); // Обновляем общее количество страниц
        });
        setSelectedExpression(null);
        setShowEditModal(false);
      })
      .catch((error) => console.error('Error deleting expression:', error));
  };

  const handleCreateExpression = () => {
    if (!courseId) {
      alert('Please select a course first.');
      return;
    }
  
    const newExpressionData = {
      text: newExpression,
      translation: newTranslation,
      theme: selectedTheme,
      course_id: courseId,
    };
  
    dispatch(addUserExpression(newExpressionData))
      .then(() => {
        dispatch(fetchExpressionsByTheme({ themeId: selectedTheme, page: currentPage })).then((response) => {
          setExpressionsCountByTheme((prev) => ({
            ...prev,
            [selectedTheme]: response.payload.count, // Обновляем общее количество выражений
          }));
          setTotalPages(Math.ceil(response.payload.count / 10)); // Обновляем общее количество страниц
        });
        setNewExpression('');
        setNewTranslation('');
        setShowCreateModal(false);
      })
      .catch((error) => console.error('Error creating expression:', error));
  };

  const handlePlayAudio = (text, lang, voice) => {
    const lang_tr = transformLang(lang);

    // Проверяем, запущено ли приложение в Telegram
    if (window.Telegram && window.Telegram.WebApp) {
      // Используем Redux для синтеза речи
      dispatch(synthesizeSpeech({ text: text, lang: lang_tr }));
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text: text, lang: lang_tr, comp: ' ', voice: voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  useEffect(() => {
    if (audioUrl) {
      setIsAudioPlaying(true); // Устанавливаем состояние воспроизведения аудио
      const audio = new Audio(audioUrl);
      audioRef.current = audio; // Сохраняем ссылку на аудио
      audio.play();
  
      // Обработчик завершения воспроизведения
      audio.addEventListener('ended', () => {
        setIsAudioPlaying(false); // Сбрасываем состояние воспроизведения аудио
        dispatch(clearAudioUrl()); // Очищаем audioUrl
      });
    }
  
    return () => {
      if (audioRef.current) {
        audioRef.current.pause(); // Останавливаем воспроизведение при размонтировании
        audioRef.current.removeEventListener('ended', () => {}); // Удаляем обработчик
      }
    };
  }, [audioUrl, dispatch]);


  const handleCheckAudioRecognition = () => {
    const expressionsForTheme = userExpressions.filter((expression) => expression.theme === selectedTheme);
    const currentExpression = expressionsForTheme[0];

    if (!currentExpression) {
      setMessage({ variant: 'error', text: 'No expression found!' });
      return;
    }

    if (audioRecognitionInput.toLowerCase() === currentExpression.text.toLowerCase()) {
      setMessage({ variant: 'success', text: 'Correct!' });
    } else {
      setMessage({ variant: 'error', text: 'Incorrect!' });
    }
    setAudioRecognitionInput('');
  };

  const toggleTranslation = (expressionId) => {
    setShowTranslations({
      ...showTranslations,
      [expressionId]: !showTranslations[expressionId],
    });
  };

  const handleFlashcards = () => {
    if (!selectedTheme) {
      setMessage({ variant: 'error', text: 'Please select a theme first!' });
      return;
    }

    const expressions = userExpressions.filter((expression) => expression.theme === selectedTheme);
    const sentences = expressions.map((expression) => ({
      text: expression.text,
      translation: expression.translation,
    }));

    navigate('/flashcards', { state: { sentences } });
  };

  const handleSuggestiveTraining = () => {
    if (!selectedTheme) {
      setMessage({ variant: 'error', text: 'Please select a theme first!' });
      return;
    }

    const expressions = userExpressions.filter((expression) => expression.theme === selectedTheme);
    const sentences = expressions.map((expression) => ({
      text: expression.text,
      translation: expression.translation,
    }));

    navigate('/suggestive-training', { state: { sentences } });
  };

  const handleCrossword = () => {
    if (!selectedTheme) {
      setMessage({ variant: 'error', text: 'Please select a theme first!' });
      return;
    }

    const expressions = userExpressions.filter((expression) => expression.theme === selectedTheme);
    const words = expressions.map((expression) => ({
      text: expression.text,
      translation: expression.translation,
    }));

    navigate('/crossword', { state: { words } });
  };

  const handleFileUpload = () => {
    if (!file || !selectedTheme) {
      setMessage({ variant: 'error', text: 'Please select a file and a theme.' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('theme', selectedTheme);
    formData.append('course_id', courseId);
    formData.append('delimiter', delimiter);

    api
      .post('/user-expressions/upload-csv/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(fetchUserExpressions({ courseId }));
        setMessage({ variant: 'success', text: 'Expressions uploaded successfully!' });
        setShowUploadModal(false);
      })
      .catch((error) => {
        console.error('Error uploading expressions:', error);
        setMessage({ variant: 'error', text: 'Error uploading expressions. Please try again.' });
      });
  };

  const actions = [
    { icon: <Add />, name: 'Create Expression', onClick: () => setShowCreateModal(true) },
    { icon: <FileUpload />, name: 'Upload CSV', onClick: () => setShowUploadModal(true) },
  ];

  return (
    <Box sx={{ padding: 0 }}>
      {message && (
        <Alert severity={message.variant} onClose={() => setMessage(null)} sx={{ mb: 2 }}>
          {message.text}
        </Alert>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
          User Expressions
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ mb: 3, justifyContent: 'space-between' }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={handleFlashcards} color="primary" sx={{ flexDirection: 'column' }}>
            <Book fontSize="large" />
            <Typography variant="body2">Flashcards</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={handleSuggestiveTraining} color="primary" sx={{ flexDirection: 'column' }}>
            <LightMode fontSize="large" />
            <Typography variant="body2">Training</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={handleCrossword} color="primary" sx={{ flexDirection: 'column' }}>
            <Quiz fontSize="large" />
            <Typography variant="body2">Crossword</Typography>
          </IconButton>
        </Grid>
      </Grid>

      <List>
        {themes.map((theme) => {
          const expressionsCount = userExpressions.filter((expression) => expression.theme === theme.id).length;
          return (
            <Paper
              key={theme.id}
              sx={{
                mb: 2,
                p: 2,
                backgroundColor: themeMode === "dark" ? "#1e1e1e" : "#ffffff",
                color: themeMode === "dark" ? "#e0e0e0" : "#000000",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 2px 4px rgba(0, 0, 0, 0.5)"
                    : "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <ListItem
                button
                onClick={() => {
                  toggleTheme(theme.id);
                  handleThemeSelect(theme.id);
                }}
                selected={selectedTheme === theme.id}
              >
                <ListItemText
                  primary={
                    <Typography variant="p">
                      {theme.name}{" "}
                      <Chip
                        label={expressionsCountByTheme[theme.id] || 0}
                        size="small"
                        color="primary"
                        sx={{ ml: 1 }}
                      />
                    </Typography>
                  }
                />
                <IconButton>
                  <ExpandMore />
                </IconButton>
              </ListItem>
              <Collapse in={expandedThemes[theme.id]}>
  {loading && <CircularProgress />}
  <List>
    {userExpressions
      .filter((expression) => expression.theme === theme.id)
      .map((expression, index, array) => (
        <React.Fragment key={expression.id}>
          <ListItem
            sx={{ flexDirection: 'column', alignItems: 'flex-start', pl: 4, mb: 1 }}
          >
            <Typography variant="body1">{expression.text}</Typography>
            {showTranslations[expression.id] && (
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                {expression.translation}
              </Typography>
            )}
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <IconButton
                onClick={() => handlePlayAudio(expression.text, user.text_language, user.text_voice)}
                size="small"
                sx={{ padding: '4px' }}
                color="primary"
              >
                <VolumeUp fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => handleExpressionClick(expression)}
                size="small"
                sx={{ padding: '4px' }}
                color="secondary"
              >
                <Edit fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => handleExpressionDelete(expression.id)}
                size="small"
                sx={{ padding: '4px' }}
                color="error"
              >
                <Delete fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => toggleTranslation(expression.id)}
                size="small"
                sx={{ padding: '4px' }}
                color="info"
              >
                <Visibility fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>
          {index < array.length - 1 && <Divider />}
        </React.Fragment>
      ))}
  </List>
  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      color="primary"
    />
  </Box>
</Collapse>            </Paper>
          );
        })}
      </List>

      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Edit Expression
          </Typography>
          {selectedExpression && (
            <Box component="form">
              <TextField
                fullWidth
                label="Expression"
                value={selectedExpression.text}
                onChange={(e) => setSelectedExpression({ ...selectedExpression, text: e.target.value })}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Translation"
                value={selectedExpression.translation || ''}
                onChange={(e) => setSelectedExpression({ ...selectedExpression, translation: e.target.value })}
                sx={{ mb: 2 }}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setShowEditModal(false)} variant="outlined">
              Close
            </Button>
            <Button onClick={() => handleExpressionUpdate(selectedExpression.id, selectedExpression)} variant="contained">
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Create New Expression
          </Typography>
          <Box component="form">
            <TextField
              fullWidth
              label="Expression"
              value={newExpression}
              onChange={(e) => setNewExpression(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Translation"
              value={newTranslation}
              onChange={(e) => setNewTranslation(e.target.value)}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Theme</InputLabel>
              <Select value={selectedTheme || ''} onChange={(e) => setSelectedTheme(e.target.value)}>
                <MenuItem value="">Select Theme</MenuItem>
                {themes.map((theme) => (
                  <MenuItem key={theme.id} value={theme.id}>
                    {theme.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setShowCreateModal(false)} variant="outlined">
              Close
            </Button>
            <Button onClick={handleCreateExpression} variant="contained">
              Create Expression
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showUploadModal} onClose={() => setShowUploadModal(false)}>
        <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Upload Expressions from CSV
          </Typography>
          <Box component="form">
            <input
              type="file"
              accept=".csv"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ marginBottom: '16px' }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Theme</InputLabel>
              <Select value={selectedTheme || ''} onChange={(e) => setSelectedTheme(e.target.value)}>
                <MenuItem value="">Select Theme</MenuItem>
                {themes.map((theme) => (
                  <MenuItem key={theme.id} value={theme.id}>
                    {theme.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Delimiter</InputLabel>
              <Select value={delimiter} onChange={(e) => setDelimiter(e.target.value)}>
                <MenuItem value=",">Comma (,)</MenuItem>
                <MenuItem value="\t">Tab (\t)</MenuItem>
                <MenuItem value="#">Hash (#)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setShowUploadModal(false)} variant="outlined">
              Close
            </Button>
            <Button onClick={handleFileUpload} variant="contained">
              Upload
            </Button>
          </Box>
        </Box>
      </Modal>

      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{ position: 'fixed', bottom: 80, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default UserExpressionsList;